<template>
  <div class="strategy">
    <v-snackbar v-model="snackbar_update" :timeout="3000" top color="success">
      <span>Update successful.</span>
      <v-btn @click="snackbar_update = false" text dark>Close</v-btn>
    </v-snackbar>

    <v-container v-if="isLoading">
      <v-progress-circular
        indeterminate
        class="primary--text"
        :width="7"
        :size="70"
      />
    </v-container>

    <v-container style="max-width: 1000px" v-else>
      <h1>My Strategy</h1>
      <v-card>
        <v-progress-linear
          v-model="fillProgress"
          color="primary"
          height="25"
          rounded
          reactive
        >
          <template v-slot="{ value }">
            <strong v-if="Math.ceil(value) < 50"
              >Progress: {{ Math.ceil(value) }}%</strong
            >
            <strong v-else class="white--text"
              >Progress: {{ Math.ceil(value) }}%</strong
            >
          </template>
        </v-progress-linear>
        <v-tabs
          :grow="false"
          :show-arrows="true"
          :vertical="!isMobile"
          background-color="grey lighten-4"
        >
          <v-tab v-for="tab in tabsList" :key="tab.index">{{ tab.name }}</v-tab>

          <!-- Investor Profile -->
          <v-tab-item>
            <v-row justify="center">
              <InvestmentLimits
                class="dark--gradient white--text"
                :darkTheme="true"
                style="max-width: 300px"
                @regCFLimit="regCFLimit = $event"
              />
              <v-container class="mx-3 px-2 py-0" style="max-width: 600px">
                <div class="overline my-3">Investor Profile</div>
                <v-row>
                  <v-col>
                    <v-form ref="step1_form">
                      <v-text-field
                        label="Net Worth (excluding primary residence)"
                        type="number"
                        prefix="$"
                        v-model="netWorth"
                        :rules="inputRules"
                      />

                      <v-text-field
                        label="Annual Income (e.g. $60,000)"
                        type="number"
                        prefix="$"
                        v-model="income"
                        :rules="inputRules"
                      />

                      <v-select
                        v-model="investorType"
                        :items="investorTypes"
                        item-text="text"
                        item-value="value"
                        label="Investment Experience"
                      />

                      <v-spacer />
                      <v-tooltip bottom max-width="300px">
                        <template v-slot:activator="{ on }">
                          <p>
                            <em>Why are you asking for this info?</em>
                            <v-icon color="primary" dark v-on="on"
                              >help_outline</v-icon
                            >
                          </p>
                        </template>
                        <span
                          >This information is used to calculate your Reg CF and
                          Reg A+ investment limits.We take your privacy
                          seriously and will never share personal data. See our
                          Privacy Policy.</span
                        >
                      </v-tooltip>
                      <v-row justify="end">
                        <v-btn
                          class="primary mb-4"
                          @click="investorUpdate"
                          v-if="investorChanged || isLoading"
                          :loading="isLoading"
                        >
                          <v-icon>save</v-icon>Save Changes
                        </v-btn>
                      </v-row>
                    </v-form>
                  </v-col>
                </v-row>
              </v-container>
            </v-row>
          </v-tab-item>

          <!-- Annual Targets -->
          <v-tab-item>
            <v-row align="center" class="mx-3">
              <v-col align="center">
                <v-card dark class="dark--gradient">
                  <v-card-text>
                    <p>Avg Check Size</p>
                    <span class="display-1 font-weight-light mr-1 white--text"
                      >$</span
                    >
                    <span
                      class="display-2 font-weight-light white--text"
                      v-text="avgCheckSize.toFixed(0)"
                    ></span>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col align="center">
                <v-card dark class="dark--gradient">
                  <v-card-text>
                    <p>Investments per Year</p>
                    <v-col>
                      <v-progress-circular
                        :rotate="270"
                        :size="100"
                        :width="15"
                        :value="(annualCount / annualDeals) * 100"
                        color="white"
                        class="white--text"
                        >{{ annualCount }} /
                        {{ annualDeals }}</v-progress-circular
                      >
                    </v-col>
                    <v-col>
                      <span class="headline font-weight-light mr-1 white--text"
                        >$</span
                      >
                      <span
                        class="display-1 font-weight-light white--text"
                        v-text="
                          ((avgCheckSize * annualDeals) / 1000).toFixed(1)
                        "
                      ></span>
                      k / year
                    </v-col>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col align="center">
                <v-card dark class="dark--gradient">
                  <v-card-text>
                    <p>Target # of Portfolio Startups</p>
                    <v-col>
                      <v-progress-circular
                        :rotate="270"
                        :size="100"
                        :width="15"
                        :value="(totalCount / totalDeals) * 100"
                        color="white"
                        class="white--text"
                        >{{ totalCount }} /
                        {{ totalDeals }}</v-progress-circular
                      >
                    </v-col>
                    <v-col>
                      <span class="headline font-weight-light mr-1 white--text"
                        >$</span
                      >
                      <span
                        class="display-1 font-weight-light white--text"
                        v-text="
                          ((netWorth * portfolioAlloc) / 100 / 1000).toFixed(1)
                        "
                      ></span>
                      k total
                    </v-col>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row justify="center">
              <v-container class="mx-3 px-2 py-0" style="max-width: 600px">
                <v-alert
                  v-if="
                    avgCheckSize * annualDeals > regCFLimit &&
                    this.netWorth < 1000000 &&
                    this.income < 200000
                  "
                  type="error"
                  >Warning: your current investment plan exceeds your 12-month
                  Reg CF limit.</v-alert
                >
                <div class="overline my-3">Annual Investment Targets</div>
                <v-row>
                  <v-col>
                    <v-form ref="step2_form">
                      <!-- Allocation Slider -->
                      <v-row align="center">
                        <span class="subheading font-weight-light mr-1"
                          >Portfolio Allocation</span
                        >
                        <span
                          class="display-3 font-weight-light"
                          v-text="portfolioAlloc"
                        ></span>
                        <span class="subheading font-weight-light mr-1">%</span>
                        <v-spacer />
                        <v-tooltip bottom max-width="300px">
                          <template v-slot:activator="{ on }">
                            <v-icon color="primary" dark v-on="on"
                              >help_outline</v-icon
                            >
                          </template>
                          <span>
                            <p>
                              Experienced angel investors typically recommend
                              allocating 5-10% of total investable assets
                              towards early-stage startups. Because of the
                              extremely high risk of this asset class, very few
                              (if any) investors should allocate more than 10%
                              to startups.
                            </p>
                            Start small, especially when starting out.
                          </span>
                        </v-tooltip>
                        <a
                          href="https://www.youtube.com/watch?v=SDB9r5zvPRg&list=PLKZ7HAgsRjaIl35O6jrYqGtrhUaVaEv_f&index=7&t=0s"
                          target="_blank"
                          >Learn More</a
                        >
                      </v-row>
                      <v-row align="center" class="mb-7">
                        <v-slider
                          v-model="portfolioAlloc"
                          track-color="grey"
                          color="blue"
                          thumb-label="always"
                          always-dirty
                          hint="Enter the percent of your overall investable assets to allocate to the startup asset class."
                          :persistent-hint="true"
                          min="1"
                          max="15"
                        >
                          <template v-slot:prepend>
                            <v-btn
                              fab
                              x-small
                              dark
                              color="blue"
                              @click="decrementAlloc"
                            >
                              <v-icon>mdi-minus</v-icon>
                            </v-btn>
                          </template>

                          <template v-slot:append>
                            <v-btn
                              fab
                              x-small
                              dark
                              color="blue"
                              @click="incrementAlloc"
                            >
                              <v-icon>mdi-plus</v-icon>
                            </v-btn>
                          </template>
                        </v-slider>
                      </v-row>
                      <v-divider />

                      <!-- Annual Deal Target Slider -->
                      <v-row align="center" class="mt-5">
                        <span class="subheading font-weight-light mr-1"
                          >Annual Target</span
                        >
                        <span
                          class="display-3 font-weight-light"
                          v-text="annualDeals"
                        ></span>
                        <span class="subheading font-weight-light mr-1"
                          >deals / year</span
                        >
                        <v-spacer />
                        <v-tooltip bottom max-width="300px">
                          <template v-slot:activator="{ on }">
                            <v-icon color="primary" dark v-on="on"
                              >help_outline</v-icon
                            >
                          </template>
                          <span
                            >Aim to build your startup portfolio slowly over
                            time. For example, if you want to target 60 startups
                            over 3 years, aim for 20 startups per year.</span
                          >
                        </v-tooltip>
                        <!-- <a
                        href="https://www.youtube.com/watch?v=s2ZNKTwLhmU"
                        target="_blank"
                        >Learn More</a>-->
                      </v-row>
                      <v-row align="center" class="mb-7">
                        <v-slider
                          v-model="annualDeals"
                          track-color="grey"
                          color="blue"
                          thumb-label="always"
                          always-dirty
                          hint="Choose your target number of startup investments per year."
                          :persistent-hint="true"
                          min="1"
                          max="100"
                        >
                          <template v-slot:prepend>
                            <v-btn
                              fab
                              x-small
                              dark
                              color="blue"
                              @click="decrementAnnualDeals"
                            >
                              <v-icon>mdi-minus</v-icon>
                            </v-btn>
                          </template>

                          <template v-slot:append>
                            <v-btn
                              fab
                              x-small
                              dark
                              color="blue"
                              @click="incrementAnnualDeals"
                            >
                              <v-icon>mdi-plus</v-icon>
                            </v-btn>
                          </template>
                        </v-slider>
                      </v-row>
                      <!-- Number of Target Startups in Portfolio Slider -->
                      <v-row align="center" class="mt-5">
                        <span class="subheading font-weight-light mr-1"
                          >Portfolio Target</span
                        >
                        <span
                          class="display-3 font-weight-light"
                          v-text="totalDeals"
                        ></span>
                        <span class="subheading font-weight-light mr-1"
                          >startups</span
                        >
                        <v-spacer />
                        <v-tooltip bottom max-width="300px">
                          <template v-slot:activator="{ on }">
                            <v-icon color="primary" dark v-on="on"
                              >help_outline</v-icon
                            >
                          </template>
                          <span
                            >Click Learn More for our WeFunder portfolio
                            analysis comparing median investor returns and
                            portfolio size. 10-20 companies is an absolute
                            minimum, but often 50-100+ is even better.</span
                          >
                        </v-tooltip>
                        <a
                          href="https://crowdwise.org/data-analysis/monte-carlo-wefunder-2018/"
                          target="_blank"
                          >Learn More</a
                        >
                      </v-row>
                      <v-row align="center" class="mb-7">
                        <v-slider
                          v-model="totalDeals"
                          track-color="grey"
                          color="blue"
                          thumb-label="always"
                          always-dirty
                          hint="Choose the target number of startup investments for your portfolio."
                          :persistent-hint="true"
                          min="5"
                          max="150"
                        >
                          <template v-slot:prepend>
                            <v-btn
                              fab
                              x-small
                              dark
                              color="blue"
                              @click="decrementTotalDeals"
                            >
                              <v-icon>mdi-minus</v-icon>
                            </v-btn>
                          </template>

                          <template v-slot:append>
                            <v-btn
                              fab
                              x-small
                              dark
                              color="blue"
                              @click="incrementTotalDeals"
                            >
                              <v-icon>mdi-plus</v-icon>
                            </v-btn>
                          </template>
                        </v-slider>
                      </v-row>
                      <v-divider />

                      <v-row justify="center">
                        <a
                          href="https://www.youtube.com/watch?v=Vbzea5TtCuo"
                          target="_blank"
                        >
                          <em
                            >Watch the investor video that explains these
                            fields</em
                          >
                        </a>
                      </v-row>

                      <v-row justify="end">
                        <v-btn
                          class="primary"
                          @click="targetUpdate"
                          v-if="targetChanged || isLoading"
                          :loading="isLoading"
                          style="max-width: 200px"
                        >
                          <v-icon>save</v-icon>Save Changes
                        </v-btn>
                      </v-row>
                    </v-form>
                  </v-col>
                </v-row>
              </v-container>
            </v-row>
          </v-tab-item>

          <!-- Portfolio Goals -->
          <v-tab-item>
            <v-row v-if="calculateRates" align="center" class="mx-3">
              <v-col align="center">
                <v-card dark class="dark--gradient">
                  <v-card-text>
                    <p>Overall Startup Portfolio Target Return</p>
                    <span
                      class="display-3 font-weight-light white--text"
                      v-text="calculateRates[0]"
                    ></span>
                    <span class="display-1 font-weight-light mr-1 white--text"
                      >&nbsp;x</span
                    >
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col align="center">
                <v-card dark class="dark--gradient">
                  <v-card-text>
                    <p>Avg Return Required for Non-Failed Investments</p>
                    <span
                      class="display-3 font-weight-light white--text"
                      v-text="calculateRates[1]"
                    ></span>
                    <span class="display-1 font-weight-light mr-1 white--text"
                      >&nbsp;x</span
                    >
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row justify="center">
              <v-container class="mx-3 px-2 py-0" style="max-width: 600px">
                <div class="overline my-3">Portfolio Goals</div>
                <v-row>
                  <v-col>
                    <v-form ref="step2_form">
                      <!-- IRR Slider -->
                      <v-row align="center">
                        <span class="subheading font-weight-light mr-1"
                          >Target IRR</span
                        >
                        <span
                          class="display-3 font-weight-light"
                          v-text="targetIRR"
                        ></span>
                        <span class="subheading font-weight-light mr-1">%</span>
                        <v-spacer />
                        <v-tooltip bottom max-width="300px">
                          <template v-slot:activator="{ on }">
                            <v-icon color="primary" dark v-on="on"
                              >help_outline</v-icon
                            >
                          </template>
                          <span
                            >According to various studies, the average IRR for
                            Angel Investors and Early-Stage VC funds is
                            26%.</span
                          >
                        </v-tooltip>
                        <a
                          href="https://crowdwise.org/data-analysis/equity-crowdfunding-returns/"
                          target="_blank"
                          >Learn More</a
                        >
                      </v-row>
                      <v-row align="center" class="mb-7">
                        <v-slider
                          v-model="targetIRR"
                          track-color="grey"
                          color="blue"
                          thumb-label="always"
                          always-dirty
                          hint="Enter the target Internal Rate of Return (IRR) for your startup portfolio."
                          :persistent-hint="true"
                          min="1"
                          max="40"
                        >
                          <template v-slot:prepend>
                            <v-btn
                              fab
                              x-small
                              dark
                              color="blue"
                              @click="decrementIRR"
                            >
                              <v-icon>mdi-minus</v-icon>
                            </v-btn>
                          </template>

                          <template v-slot:append>
                            <v-btn
                              fab
                              x-small
                              dark
                              color="blue"
                              @click="incrementIRR"
                            >
                              <v-icon>mdi-plus</v-icon>
                            </v-btn>
                          </template>
                        </v-slider>
                      </v-row>
                      <v-divider />
                      <!-- Exit Timeline Slider -->
                      <v-row align="center" class="mt-5">
                        <span class="subheading font-weight-light mr-1"
                          >Time</span
                        >
                        <span
                          class="display-3 font-weight-light"
                          v-text="targetTime"
                        ></span>
                        <span class="subheading font-weight-light mr-1"
                          >years</span
                        >
                        <v-spacer />
                        <v-tooltip bottom max-width="300px">
                          <template v-slot:activator="{ on }">
                            <v-icon color="primary" dark v-on="on"
                              >help_outline</v-icon
                            >
                          </template>
                          <span
                            >Keep in mind the average time to exit an
                            early-stage investing was found to be 5-8 years on
                            average, with many larger returns taking even
                            longer.</span
                          >
                        </v-tooltip>
                        <a
                          href="https://crowdwise.org/data-analysis/equity-crowdfunding-returns/"
                          target="_blank"
                          >Learn More</a
                        >
                      </v-row>
                      <v-row align="center" class="mb-7">
                        <v-slider
                          v-model="targetTime"
                          track-color="grey"
                          color="blue"
                          thumb-label="always"
                          always-dirty
                          hint="Select your target investment timeframe for your startup portfolio."
                          :persistent-hint="true"
                          min="1"
                          max="15"
                        >
                          <template v-slot:prepend>
                            <v-btn
                              fab
                              x-small
                              dark
                              color="blue"
                              @click="decrementTime"
                            >
                              <v-icon>mdi-minus</v-icon>
                            </v-btn>
                          </template>

                          <template v-slot:append>
                            <v-btn
                              fab
                              x-small
                              dark
                              color="blue"
                              @click="incrementTime"
                            >
                              <v-icon>mdi-plus</v-icon>
                            </v-btn>
                          </template>
                        </v-slider>
                      </v-row>
                      <v-divider />
                      <!-- Failure Rate Slider -->
                      <v-row align="center" class="mt-5">
                        <span class="subheading font-weight-light mr-1"
                          >Failure Rate</span
                        >
                        <span
                          class="display-3 font-weight-light"
                          v-text="failureRate"
                        ></span>
                        <span class="subheading font-weight-light mr-1">%</span>
                        <v-spacer />
                        <v-tooltip bottom max-width="300px">
                          <template v-slot:activator="{ on }">
                            <v-icon color="primary" dark v-on="on"
                              >help_outline</v-icon
                            >
                          </template>
                          <span
                            >Failure rates will vary depending on the stage
                            (early-stage vs. late-stage) and many other
                            factors.</span
                          >
                        </v-tooltip>
                        <a
                          href="https://www.youtube.com/watch?v=s2ZNKTwLhmU"
                          target="_blank"
                          >Learn More</a
                        >
                      </v-row>
                      <v-row align="center" class="mb-7">
                        <v-slider
                          v-model="failureRate"
                          track-color="grey"
                          color="blue"
                          thumb-label="always"
                          always-dirty
                          hint="Select the estimated failure rate of the startups you will be investing in."
                          :persistent-hint="true"
                          min="10"
                          max="95"
                        >
                          <template v-slot:prepend>
                            <v-btn
                              fab
                              x-small
                              dark
                              color="blue"
                              @click="decrementRate"
                            >
                              <v-icon>mdi-minus</v-icon>
                            </v-btn>
                          </template>

                          <template v-slot:append>
                            <v-btn
                              fab
                              x-small
                              dark
                              color="blue"
                              @click="incrementRate"
                            >
                              <v-icon>mdi-plus</v-icon>
                            </v-btn>
                          </template>
                        </v-slider>
                      </v-row>

                      <v-row justify="center">
                        <a
                          href="https://www.youtube.com/watch?v=Vbzea5TtCuo"
                          target="_blank"
                        >
                          <em
                            >Watch the investor video that explains these
                            fields</em
                          >
                        </a>
                      </v-row>

                      <v-row justify="end">
                        <v-btn
                          class="primary"
                          @click="portfolioUpdate"
                          v-if="portfolioChanged || isLoading"
                          :loading="isLoading"
                          style="max-width: 200px"
                        >
                          <v-icon>save</v-icon>Save Changes
                        </v-btn>
                      </v-row>
                    </v-form>
                  </v-col>
                </v-row>
              </v-container>
            </v-row>
          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import { mapState, mapGetters } from "vuex";
import InvestmentLimits from "../components/InvestmentLimits.vue";

export default {
  name: "Strategy",
  mixins: [validationMixin],
  components: { InvestmentLimits },
  // TODO: Check that errors display correctly (Vuex or local) for name Errors and validation
  validations: {
    // firstName: { required, minLength: minLength(1) },
    // lastName: { required, minLength: minLength(1) }
  },
  data: function () {
    return {
      regCFLimit: null, //Emitted from InvsetmentLimits component for use in step 2 comparison
      inputRules: [
        (v) => !!v || "Required",
        (v) => (v && v.length > 0) || "Cannot be blank",
      ],
      isMobile: false,
      tabsList: [
        { index: 0, name: "1 - Investor Profile" },
        { index: 1, name: "2 - Annual Targets" },
        { index: 2, name: "3 - Portfolio Goals" },
      ],
      rules: [(value) => !value],
      investorTypes: [
        { value: "new_inv", text: "New to Investing" },
        { value: "public_inv", text: "Public Market Experience" },
        { value: "crowd_inv", text: "Crowdfund Investing Experience" },
        { value: "angel_inv", text: "Angel Investor" },
        { value: "vc_inv", text: "Venture Capital" },
        { value: "family_inv", text: "Family Office" },
        { value: "other_inv", text: "Other" },
      ],
      snackbar_update: false,
      updateNetWorth: null,
      updateIncome: null,
      updateInvestorType: null,
      updateTargetIRR: null,
      updateTargetTime: null,
      updateFailureRate: null,
      updatePortfolioAlloc: null,
      updateTotalDeals: null,
      updateAnnualDeals: null,
    };
  },
  computed: {
    ...mapGetters(["isLoading", "error"]),
    loadedInvestments() {
      return this.$store.getters.loadedInvestments;
    },
    annualCount() {
      let mstoYear = 365 * 24 * 60 * 60 * 1000;
      let currentDate = new Date();

      let annualInvestments = this.loadedInvestments.filter(
        (investment) =>
          currentDate.getTime() - new Date(investment.date).getTime() < mstoYear
      );

      return annualInvestments.length;
    },
    totalCount() {
      return this.loadedInvestments.length;
    },
    avgCheckSize() {
      return (this.netWorth * this.portfolioAlloc) / 100 / this.totalDeals;
    },
    // These two computed functions check for any updates and then activate the Save Changes button
    fillProgress() {
      let progress = 0;
      if (
        this.$store.getters.netWorth &&
        this.$store.getters.income &&
        this.$store.getters.investorType
      ) {
        progress = 33;
      }
      if (
        this.$store.getters.targetIRR &&
        this.$store.getters.targetTime &&
        this.$store.getters.failureRate
      ) {
        progress += 33;
      }
      if (
        this.$store.getters.portfolioAlloc &&
        this.$store.getters.totalDeals &&
        this.$store.getters.annualDeals
      ) {
        progress += 34;
      }

      return progress;
    },
    investorChanged() {
      if (
        this.updateNetWorth !== null &&
        this.updateNetWorth !== this.$store.getters.netWorth
      ) {
        return true;
      } else if (
        this.updateIncome !== null &&
        this.updateIncome !== this.$store.getters.income
      ) {
        return true;
      } else if (
        this.updateInvestorType !== null &&
        this.updateInvestorType !== this.$store.getters.investorType
      ) {
        return true;
      } else {
        return false;
      }
    },
    netWorth: {
      get() {
        return this.$store.state.Auth.user.netWorth;
      },
      set(value) {
        this.updateNetWorth = value;
      },
    },
    income: {
      get() {
        return this.$store.state.Auth.user.income;
      },
      set(value) {
        this.updateIncome = value;
      },
    },
    investorType: {
      get() {
        return this.$store.state.Auth.user.investorType;
      },
      set(value) {
        this.updateInvestorType = value;
      },
    },
    portfolioChanged() {
      if (
        this.updateTargetIRR !== null &&
        this.updateTargetIRR !== this.$store.state.Auth.user.targetIRR
      ) {
        return true;
      } else if (
        this.updateTargetTime !== null &&
        this.updateTargetTime !== this.$store.state.Auth.user.targetTime
      ) {
        return true;
      } else if (
        this.updateFailureRate !== null &&
        this.updateFailureRate !== this.$store.state.Auth.user.failureRate
      ) {
        return true;
      } else {
        return false;
      }
    },
    targetIRR: {
      get() {
        if (this.updateTargetIRR) {
          return this.updateTargetIRR;
        } else if (this.$store.getters.targetIRR) {
          return this.$store.getters.targetIRR;
        } else {
          return 15; //Default value for first time
        }
      },
      set(value) {
        this.updateTargetIRR = value;
      },
    },
    targetTime: {
      get() {
        if (this.updateTargetTime) {
          return this.updateTargetTime;
        } else if (this.$store.getters.targetTime) {
          return this.$store.getters.targetTime;
        } else {
          return 8; //Default value for first time
        }
      },
      set(value) {
        this.updateTargetTime = value;
      },
    },
    failureRate: {
      get() {
        if (this.updateFailureRate) {
          return this.updateFailureRate;
        } else if (this.$store.getters.failureRate) {
          return this.$store.getters.failureRate;
        } else {
          return 60; //Default value for first time
        }
      },
      set(value) {
        this.updateFailureRate = value;
      },
    },
    targetChanged() {
      if (
        this.updatePortfolioAlloc !== null &&
        this.updatePortfolioAlloc !== this.$store.getters.portfolioAlloc
      ) {
        return true;
      } else if (
        this.updateTotalDeals !== null &&
        this.updateTotalDeals !== this.$store.getters.totalDeals
      ) {
        return true;
      } else if (
        this.updateAnnualDeals !== null &&
        this.updateAnnualDeals !== this.$store.getters.annualDeals
      ) {
        return true;
      } else {
        return false;
      }
    },
    portfolioAlloc: {
      get() {
        if (this.updatePortfolioAlloc) {
          return this.updatePortfolioAlloc;
        } else if (this.$store.getters.portfolioAlloc) {
          return this.$store.getters.portfolioAlloc;
        } else {
          return 5; //Default value for first time
        }
      },
      set(value) {
        this.updatePortfolioAlloc = value;
      },
    },
    totalDeals: {
      get() {
        if (this.updateTotalDeals) {
          return this.updateTotalDeals;
        } else if (this.$store.getters.totalDeals) {
          return this.$store.getters.totalDeals;
        } else {
          return 50; //Default value for first time
        }
      },
      set(value) {
        this.updateTotalDeals = value;
      },
    },
    annualDeals: {
      get() {
        if (this.updateAnnualDeals) {
          return this.updateAnnualDeals;
        } else if (this.$store.getters.annualDeals) {
          return this.$store.getters.annualDeals;
        } else {
          return 10; //Default value for first time
        }
      },
      set(value) {
        this.updateAnnualDeals = value;
      },
    },
    calculateRates() {
      //Do Calculations for Portfolio
      let yourIRR = 0;
      let yourFailure = 0;
      let yourTime = 0;

      if (this.updateTargetIRR) {
        yourIRR = +this.updateTargetIRR;
      } else {
        yourIRR = +this.targetIRR;
      }
      if (this.updateFailureRate) {
        yourFailure = +this.updateFailureRate;
      } else {
        yourFailure = +this.failureRate;
      }
      if (this.updateTargetTime) {
        yourTime = +this.updateTargetTime;
      } else {
        yourTime = +this.targetTime;
      }

      let portMult = Math.pow(1 + yourIRR / 100, yourTime);
      let indivMult = portMult / (1 - yourFailure / 100);
      let indivRate = Math.pow(indivMult, 1 / yourTime) - 1;

      return [
        +portMult.toFixed(1),
        +indivMult.toFixed(1),
        +indivRate.toFixed(1) * 100,
      ];
    },
  },
  created() {
    window.addEventListener("resize", this.onResize); //For conditional rendering of vertical vs. horizontal tabs
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize); //For conditional rendering of vertical vs. horizontal tabs
  },
  methods: {
    decrementIRR() {
      this.targetIRR--;
    },
    incrementIRR() {
      this.targetIRR++;
    },
    decrementTime() {
      this.targetTime--;
    },
    incrementTime() {
      this.targetTime++;
    },
    decrementRate() {
      this.failureRate--;
    },
    incrementRate() {
      this.failureRate++;
    },
    decrementAlloc() {
      this.portfolioAlloc--;
    },
    incrementAlloc() {
      this.portfolioAlloc++;
    },
    decrementTotalDeals() {
      this.totalDeals--;
    },
    incrementTotalDeals() {
      this.totalDeals++;
    },
    decrementAnnualDeals() {
      this.annualDeals--;
    },
    incrementAnnualDeals() {
      this.annualDeals++;
    },
    onResize() {
      if (window.innerWidth > 780) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
    },
    investorUpdate(e) {
      let investorProfile = {};

      if (this.updateNetWorth !== null) {
        investorProfile.netWorth = this.updateNetWorth;
      }
      if (this.updateIncome !== null) {
        investorProfile.income = this.updateIncome;
      }
      if (this.updateInvestorType !== null) {
        investorProfile.investorType = this.updateInvestorType;
      }

      // Commit all changes to Firestore at the same time to prevent 1 update per second limit
      this.$store
        .dispatch("updateInvestorProfile", investorProfile)
        .then((response) => {
          this.snackbar_update = true;
          this.updateNetWorth = null;
          this.updateIncome = null;
          this.updateInvestorType = null;
        }),
        (error) => {
          console.error(error);
        };
    },
    portfolioUpdate() {
      let investorProfile = {};

      if (this.updateTargetIRR !== null) {
        investorProfile.targetIRR = this.updateTargetIRR;
      }
      if (this.updateTargetTime !== null) {
        investorProfile.targetTime = this.updateTargetTime;
      }
      if (this.updateFailureRate !== null) {
        investorProfile.failureRate = this.updateFailureRate;
      }

      this.$store.dispatch("updateInvestorProfile", investorProfile).then(
        (response) => {
          //TODO: Sliders jump quickly - is it not waiting? Or get() doesn't trigger until after reset
          this.snackbar_update = true;
          this.updateTargetIRR = null;
          this.updateTargetTime = null;
          this.updateFailureRate = null;
        },
        (error) => {
          console.error(error);
        }
      );
    },
    targetUpdate() {
      let investorProfile = {};

      if (this.updatePortfolioAlloc !== null) {
        investorProfile.portfolioAlloc = this.updatePortfolioAlloc;
      }
      if (this.updateTotalDeals !== null) {
        investorProfile.totalDeals = this.updateTotalDeals;
      }
      if (this.updateAnnualDeals !== null) {
        investorProfile.annualDeals = this.updateAnnualDeals;
      }

      this.$store.dispatch("updateInvestorProfile", investorProfile).then(
        (response) => {
          //TODO: Sliders jump quickly - is it not waiting? Or get() doesn't trigger until after reset
          this.snackbar_update = true;
          this.updatePortfolioAlloc = null;
          this.updateTotalDeals = null;
          this.updateAnnualDeals = null;
        },
        (error) => {
          console.error(error);
        }
      );
    },
  },
};
</script>
