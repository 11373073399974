<template>
  <v-card class="pt-3 px-5 my-3" min-width="250px">
    <div class="overline mb-0">Investment Limits</div>
    <v-col v-if="!netWorth || !income">
      <v-alert type="warning"
        >Enter Net Worth and Income to calculate limits.</v-alert
      >
    </v-col>
    <v-row v-else>
      <v-col align="center">
        Reg CF
        <v-tooltip bottom max-width="300px">
          <template v-slot:activator="{ on }">
            <v-icon class="mb-1" color="secondary" v-on="on"
              >help_outline</v-icon
            >
          </template>
          <span
            >12-month Reg CF investment limit. Updated based on your Net Worth
            and Income on the My Strategy Page.</span
          >
        </v-tooltip>
        <v-progress-circular
          v-if="!isAccredited"
          :rotate="270"
          :size="100"
          :width="15"
          :value="regCFProgress"
          :color="barColor"
          class="mb-3"
          >{{ regCFProgress.toFixed(1) }} %</v-progress-circular
        >
        <div class="overline" v-if="!isAccredited">
          $ {{ (regCFSum.sumValues / 1000).toFixed(1) }}k /
          {{ (regCFLimit / 1000).toFixed(1) }}k
        </div>
        <v-progress-circular
          v-if="isAccredited"
          :rotate="270"
          :size="100"
          :width="15"
          :value="100"
          :color="barColor"
          >No Limit</v-progress-circular
        >
        <div class="overline" v-if="isAccredited">Accredited Investor</div>
      </v-col>
      <v-col align="center">
        Reg A+
        <v-tooltip bottom max-width="300px">
          <template v-slot:activator="{ on }">
            <v-icon class="mb-1" color="secondary" v-on="on"
              >help_outline</v-icon
            >
          </template>
          <span
            >Per deal investment limit for non-accredited investors in Reg A+,
            Tier 2. No limits on Tier 1 Reg A+. Updated based on your Net Worth
            and Income on the Strategy Page.</span
          >
        </v-tooltip>
        <v-progress-circular
          v-if="!isAccredited"
          :rotate="270"
          :size="100"
          :width="15"
          :value="100"
          :color="barColor"
          >$ {{ numberWithCommas(regALimit) }}</v-progress-circular
        >
        <v-progress-circular
          v-if="isAccredited"
          :rotate="270"
          :size="100"
          :width="15"
          :value="100"
          :color="barColor"
          >No Limit</v-progress-circular
        >
        <div class="overline" v-if="isAccredited">Accredited Investor</div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { formatMixin } from "../components/mixins/formatMixin.js";

export default {
  name: "InvestmentLimits",
  props: ["darkTheme"],
  mixins: [formatMixin],
  computed: {
    barColor() {
      if (this.darkTheme) {
        return "white";
      } else {
        return "tertiary";
      }
    },
    netWorth() {
      return this.$store.getters.netWorth;
    },
    income() {
      return this.$store.getters.income;
    },
    regCFLimit() {
      let invNW = +this.netWorth;
      let invSalary = +this.income;
      let invLimit = 0;

      const secLimit = 107000; //Used in the limit calculations - may change each year

      //Calculate Reg CF 12-month Limit
      if (invNW >= secLimit && invSalary >= secLimit) {
        invLimit = Math.max(0.1 * invNW, 0.1 * invSalary);
      } else {
        invLimit = Math.max(2200, Math.max(0.05 * invNW, 0.05 * invSalary));
      }

      this.$emit("regCFLimit", invLimit); //Make RegCF Limit accessible in Parent
      return invLimit;
    },
    isAccredited() {
      let invNW = +this.netWorth;
      let invSalary = +this.income;

      //Check and display Accredited Investor status
      if (invNW >= 1000000 || invSalary >= 200000) {
        return true;
      } else {
        return false;
      }
    },
    regALimit() {
      let invNW = +this.netWorth;
      let invSalary = +this.income;
      let invLimit = 0;

      if (this.isAccredited) {
        return 999999;
      } else {
        invLimit = Math.max(0.1 * invNW, 0.1 * invSalary);
        return invLimit.toFixed(0);
      }
    },
    annualInvestments() {
      let mstoYear = 365 * 24 * 60 * 60 * 1000;
      let currentDate = new Date();

      return this.$store.getters.loadedInvestments.filter(
        (investment) =>
          currentDate.getTime() - new Date(investment.date).getTime() < mstoYear
      );
    },
    regCFSum() {
      let annualRegCF = this.annualInvestments.filter(
        (investment) => investment.regType === "Reg CF"
      );

      let sumValues = annualRegCF.reduce(function (prev, cur) {
        return +prev + +cur.amount;
      }, 0);
      return { sumValues };
    },
    // regASum() {
    //   let sumValues = this.annualInvestments.reduce(function(prev, cur) {
    //     return +prev + +cur.amount;
    //   }, 0);

    //   return { sumValues };
    // },
    regCFProgress() {
      let cfPercent = this.regCFSum.sumValues / this.regCFLimit;
      return cfPercent * 100;
    },
    // regAProgress() {
    //   let cfPercent = this.regASum.sumValues / this.regALimit;
    //   return cfPercent * 100;
    // }
  },
};
</script>